export enum ColorTheme {
  Blue = '#4198cb',
  Green = '#377d22',
  DarkGreen = '#006400',
  Orange = '#ec5c2c',
  LightYellow = '#FECE02',
  Yellow = '#edba42',
  DarkYellow = '#ffaf00',
  DarkerYellow = '#ffbf00',
  DarkestYelow = '#FF9900',
  Red = '#b02318',
  DarkerRed = '#CC3300',
  DarkRed = '#800000',
  White = '#fff',
  Black = '#000',
  Pink = '#d00068',
  DarkPink = '#D00068',
  SkyBlue = '#0099d0',
  ElectricBlue = '#00AEC0',
  HeartRed = '#AC0000',
}
