import React from 'react';
import MDX from '@mdx-js/runtime';
import {
  PageWrapper,
  Header,
  Content,
  UnorderedList,
  ListItem,
} from '../shared/Page';
import { FollowOnFb } from '../shared/FollowOnFb';
import { SectionProps } from './types';

const components = {
  h1: (props) => <Header id={'society'} {...props} />,
  p: (props) => <Content {...props} />,
  ul: (props) => <UnorderedList {...props} />,
  li: (props) => <ListItem {...props} />,
};

export function Society({ theme }: SectionProps) {
  return (
    <PageWrapper padding={'0 0 2.5rem 0'} theme={theme}>
      <MDX components={components}>{`# DRAUGIJA

Gamtakultūrinė draugija – tai laisva ir savarankiška organizacija, vienijanti kūrėjus, kurie siekia realizuoti kino ir teatro kalbų sintezę kaip iš esmės psichogeografinę kalbą bei savirefleksinę keliavimo formą, taip pat kiną ir teatrą kaip žaidimą.

Tyrinėdami geografinę perspektyvą, kaip kinas ir teatras atliepia aplinką, mes ne tik geriau suprantame, kaip veikia jie patys, bet ir pažįstame, kokiais būdais kuriame aplinką socialiai savo geografinėje vaizduotėje.

Santykis su aplinka apima tiek lokalumo, tiek dinaminę puses. Tačiau judėjimo aplinkoje, t. y. keliavimo, sąvoka formuoja specifinę šių kategorijų reikšmę. Atitinkamai ir mąstymo procese kelionės suvokimas, t. y. kelionės samprata, nėra vien pasyvus kelionės atspindėjimas, o priešingai – tai aktyvaus kelionės proceso, lokalumo ir dinamikos rezultatas sąmonėje ir jos raidoje.

**Mūsų principai:**

- **Draugystė.** Visi esame draugai, veikiame kiekvienas ir visi kartu.
- **Dalinimasis.** Viską ką turime, naudojame kartu ir kuriame kartu visiems.
- **Saviraiška.** Kiekvienas esame laisvas išreikšti save ir visi laisvi reikštis kartu. 
- **Dalyvavimas.** Svarbus kiekvieno gilus įsitraukimas ir asmeninis indėlis, mes veikiame, ne stebime.
- **Savarankiškumas.** Savarankiškai apsirūpiname ir esame pasirengę išgyventi iš to, ką sukursime. 
- **Bendruomeniškumas.** Socialinės sąlygos ir bendruomeniniai poreikiai visada yra pagrindas veikti kūrybiškai, siekti tikslų kartu su kitais.
- **Antivartotojiškumas.** Remdamiesi dalinimosi dvasia, kuriame tarpusavio aplinką, kurios netrikdo jokia komercinė veikla, dalyvavimo patirties neturi pakeisti vartojimas.
- **Nekenkimas.** Nekenkiame kitiems, neteršiame aplinkos, savo kūno ir sąmonės.
- **Tobulėjimas.** Esame atviri patyrimui, stengiamės augti, padedame kitiems, paliekame aplinką švaresnę negu randame, patys tampame švaresni. 
- **Pozityvumas.** Visada mokame džiaugtis, stengiamės su kitais dalintis tuo, ką savyje turime geriausio.
- **Kūrybiškumas.** Siekiame to, ko dar nėra, nesivadovaujame šablonais.
- **Praktiškumas.** Žinojimą grindžiame praktiniu patyrimu, nesilaikome išankstinių stereotipų.
- **Teoriškumas.** Nuosekliai atspindime tai, ką patiriame, kaupiame, analizuojame ir apibendriname, pažinimo rezultatus taikome veikime.
- **Autentiškumas.** Kiekvieną reiškinį stengiamės pažinti kaip jo turiniui būdingą visumą, nelyginame vieno pagal kitą.
- **Dinamiškumas.** Atspindime gyvai, keičiamės kartu su sąlygomis, neužsidarome sustingusiose formulėse.
- **Žaismingumas.** Visose veiklose randame žaidimo elementą, žaisdami mokomės.
- **Savistaba.** Stebime save ir apmąstome savo suvokimą bei veiklą, esame savikritiški.
`}</MDX>
      <br />
      <Content textAlign={'center'}>
        <FollowOnFb />
      </Content>
    </PageWrapper>
  );
}
