import React from 'react';
import styled from 'styled-components';
import { ColorTheme } from '../constants/ColorTheme';
import { device } from '../constants/device';
import { LanguagePicker } from './LanguagePicker';

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.1rem;

  @media ${device.laptop} {
    padding: 0 1.5rem;
    flex-direction: row;
  }
`;

const Slogan = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Title = styled.h1`
  color: ${ColorTheme.Orange};
  text-align: right;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 26px;

  @media ${device.laptop} {
    margin-right: 30px;
    font-size: 3.2rem;
  }
`;

const Logo = styled.img`
  width: 100px;

  @media ${device.laptop} {
    width: 180px;
  }
`;

export function Hero() {
  return (
    <HeroWrapper>
      <LanguagePicker />
      <Slogan>
        <Title>
          Gamtakultūrinė
          <br />
          draugija
        </Title>
        <Logo src="./flag.jpg" />
      </Slogan>
    </HeroWrapper>
  );
}
