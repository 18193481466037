import React from 'react'
import styled from 'styled-components'
import { Hero, Menu } from '.'
import { device } from '../constants/device'

const PageWrapper = styled.header`
  display: flex;
  flex-direction: column;
  min-height: -webkit-fill-available;
  overflow: hidden;
  padding: 1rem;
  background: url(virselis.jpg) no-repeat top center scroll;
  background-size: auto 60%;
  background-color: black;

  @media ${device.mobileL} {
    background-size: auto 100%;
  }

  @media ${device.laptop} {
    min-height: 100vh;
    background-size: 100% 98%;
  }
`

const MenuWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

export function HeroSection() {
  return (
    <PageWrapper>
      <Hero />
      <MenuWrapper>
        <Menu />
      </MenuWrapper>
    </PageWrapper>
  )
}
