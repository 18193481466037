import styled from 'styled-components';
import { ColorTheme } from '../constants/ColorTheme';

const Link = styled.a`
  color: ${ColorTheme.White};
  text-transform:
`;

export function MoreInfo() {
  return (
    <Link href="https://ekspedicijos.lt/meile-gamtai.html" target="_blank">
      <b>Daugiau apie filmą skaitykite čia</b>
    </Link>
  );
}
