import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { PageWrapper, Header, Content } from '../shared/Page';
import { ColorTheme } from '../constants/ColorTheme';
import { device } from '../constants/device';
import { SectionProps } from './types';

const HeaderWrapper = styled.div`
  text-align: center;
`;

const IFrame = styled.iframe`
  display: block;
  margin: 24px auto 0;
  width: 90%;
  max-width: 560px;

  @media ${device.laptop} {
    margin: 24px auto 0;
    width: 560px;
  }
`;

const components = {
  p: (props) => (
    <Content maxWidth={'900px'} theme={ColorTheme.Orange} {...props} />
  ),
};

export function Documentary({ theme }: SectionProps) {
  return (
    <PageWrapper padding={'0 0 2.5rem 0'} theme={theme}>
      <HeaderWrapper>
        <Header
          textAlign={'center'}
          theme={ColorTheme.White}
          id={'documentary'}
        >
          TEMINĖS DOKUMENTIKOS
        </Header>
        <br />
      </HeaderWrapper>
      <IFrame
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/aih_KViMmm8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <IFrame
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/aHFVUC9X-O4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <br />
    </PageWrapper>
  );
}
