import './styles.css';
import React from 'react';
import { HeroSection } from './heroSection';
import { ColorTheme } from './constants/ColorTheme';
import {
  Nomad,
  Camping,
  Society,
  Expeditions,
  Friends,
  PassedSummerCamp,
  Documentary,
  UpperMongolia,
  FlyingCarpet,
  Games,
} from './sections';
import { BackToTop } from './shared/BackToTop';
import { LitExpeditions } from './sections/LitExpeditions';

export function App() {
  return (
    <>
      <HeroSection />
      <Society theme={ColorTheme.Orange} />
      <Nomad theme={ColorTheme.Green} />
      <UpperMongolia theme={ColorTheme.LightYellow} />
      <Expeditions theme={ColorTheme.DarkRed} />
      <FlyingCarpet theme={ColorTheme.ElectricBlue} />
      <LitExpeditions theme={ColorTheme.Pink} />
      <Camping theme={ColorTheme.Yellow} />
      <PassedSummerCamp theme={ColorTheme.DarkGreen} />
      <Games theme={ColorTheme.DarkerRed} />
      <Documentary theme={ColorTheme.SkyBlue} />
      <Friends theme={ColorTheme.DarkYellow} />
      <BackToTop />
    </>
  );
}
