enum ScreenSize {
  MobileS = '320px',
  MobileM = '375px',
  MobileL = '425px',
  Tablet = '768px',
  Laptop = '1024px',
  LaptopL = '1440px',
  Desktop = '2560px',
}

export const device = {
  mobileS: `(min-width: ${ScreenSize.MobileS})`,
  mobileM: `(min-width: ${ScreenSize.MobileM})`,
  mobileL: `(min-width: ${ScreenSize.MobileL})`,
  tablet: `(min-width: ${ScreenSize.Tablet})`,
  laptop: `(min-width: ${ScreenSize.Laptop})`,
  laptopL: `(min-width: ${ScreenSize.LaptopL})`,
  desktop: `(min-width: ${ScreenSize.Desktop})`,
  desktopL: `(min-width: ${ScreenSize.Desktop})`,
}
