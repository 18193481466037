import React from 'react';
import styled from 'styled-components';
import { PageWrapper, Header } from '../shared/Page';
import { ColorTheme } from '../constants/ColorTheme';
import { SectionProps } from './types';
import { device } from '../constants/device';

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.5rem 0;
  flex-wrap: wrap;

  @media ${device.laptop} {
    flex-wrap: nowrap;
  }

  > * {
    padding-top: 0.5rem;
    @media ${device.laptop} {
      padding-top: 0rem;
    }
  }
`;

const Logo = styled.img`
  display: block;
  max-height: 80px;
  width: auto;
  height: auto;

  @media ${device.laptop} {
    max-height: 150px;
  }
`;

export function Friends({ theme }: SectionProps) {
  return (
    <PageWrapper padding={'0 0 3rem 0'} theme={theme}>
      <Header textAlign={'center'} theme={ColorTheme.Orange} id={'friends'}>
        Mūsų draugai:
      </Header>
      <br />
      <br />
      <Container>
        <a href="https://www.llti.lt" rel="noreferrer" target="_blank">
          <Logo
            src="./logos/Llti.png"
            alt="lietuvių literatūros ir tautosakos institutas"
          />
        </a>
        <a href="https://zaislumuziejus.lt" rel="noreferrer" target="_blank">
          <Logo src="./logos/zmuz.png" alt="žaislų muziejus" />
        </a>
        <a href="https://asvejosparkas.lt" rel="noreferrer" target="_blank">
          <Logo src="./logos/asvejos.png" alt="asvejos" />
        </a>
        <a href="https://aic.lt" rel="noreferrer" target="_blank">
          <Logo src="./logos/aic.jpg" alt="armijai ir civiliams" />
        </a>
        <a href="https://www.promaksa.lt" rel="noreferrer" target="_blank">
          <Logo src="./logos/promaksa.png" alt="promaksa" />
        </a>
        <Logo src="./logos/oxtv.png" alt="oxtv" />
      </Container>
      <br />
    </PageWrapper>
  );
}
