import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { device } from '../constants/device';
import { ColorTheme } from '../constants/ColorTheme';
import { PageWrapper, Header, Content } from '../shared/Page';
import { PageImage } from '../shared';
import { SectionProps } from './types';

const ContentWrapper = styled.div`
  @media ${device.laptop} {
    display: flex;
  }
`;

const TextWrapper = styled.div`
  padding-bottom: 2.5rem;

  @media ${device.laptop} {
    padding-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  flex: 1 0 250px;
`;

const components = {
  h1: (props) => <Header theme={ColorTheme.Green} id={'camping'} {...props} />,
  p: (props) => <Content theme={ColorTheme.Green} color={'white'} {...props} />,
};

export function Camping({ theme }: SectionProps) {
  return (
    <PageWrapper theme={theme}>
      <ContentWrapper>
        <TextWrapper>
          <MDX components={components}>{`# STOVYKLAVIMAS IR ŽAIDIMAI

Ekspedicinių stovyklų sistema  – neatsiejama gamtakultūrinio keliavimo dalis.  Ekspedicijos metu pasirinktose kelio atkarpose yra įrengiami stovyklų punktai, kurių paskirtis – įsisavinti vietovę ir įveiktą kelią žaidžiant, atliekant užduotis bei rengiantis tolesnei kelionės atkarpai.

Užduočių-žaidimų forma konkrečioje stovyklavietėje priklauso nuo tos vietos aplinkos sąlygų, bendros ekspedicijos strategijos, spontaniškų kiekvieno keliautojo sprendimų ir patirčių. Kiekvienos stovyklos tikslas – kuo labiau patirti aplinką, pažinti ją savireflektyviai.

Taip pat stovyklų veikla, kaip ir visa ekspedicija, leidžia kiekvienam ir visiems kartu kurti bendruomenę, praktiškai įgyvendinti bendrus tikslus, realizuoti meninę raišką, gilinti žinias bei tarpusavio supratimą.

Kiekvienos ekspedicijos stovyklų skaičius priklauso nuo bendro konkrečios kelionės plano, kelio ilgio, vietovės sąlygų. Stovyklos būna pagrindinės arba tarpinės.

`}</MDX>
        </TextWrapper>
        <ImageWrapper>
          <PageImage src="/stovyklos.jpg" />
        </ImageWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
}
