import React from 'react';
import styled from 'styled-components';
import { ColorTheme } from '../constants/ColorTheme';
import { device } from '../constants/device';

const ItemList = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  width: 70%;

  @media ${device.laptop} {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3rem;
    flex-wrap: wrap;
  }

  @media ${device.desktop} {
    flex-wrap: nowrap;
  }
`;

interface MenuItemProps {
  theme?: ColorTheme;
}

const MenuItem = styled.li<MenuItemProps>`
  color: ${({ theme }) => theme};
  border: 5px solid currentColor;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  margin: 1rem 0;
  padding: 0.5rem 0;

  @media ${device.laptop} {
    padding: 0.5rem 1rem;
    min-width: 250px;
  }
`;

export function Menu() {
  return (
    <ItemList>
      <a href="#society">
        <MenuItem theme={ColorTheme.Orange}>Draugija</MenuItem>
      </a>
      <a href="#nomad">
        <MenuItem theme={ColorTheme.Orange}>Skrajojanti klajoklė</MenuItem>
      </a>
      <a href="#upper-mongolia">
        <MenuItem theme={ColorTheme.Orange}>Ekspedicijos į aukštutinę mongoliją</MenuItem>
      </a>
      <a href="#expeditions">
        <MenuItem theme={ColorTheme.Orange}>Kino ekspedicijos</MenuItem>
      </a>
      <a href="#flying-carpet">
        <MenuItem theme={ColorTheme.Orange}>Kelionės skraidančiu kilimu</MenuItem>
      </a>
      <a href="#lit-expeditions">
        <MenuItem theme={ColorTheme.Orange}>Literatūrinės ekspedicijos</MenuItem>
      </a>
      <a href="#camping">
        <MenuItem theme={ColorTheme.Orange}>Stovyklavimas ir žaidimai</MenuItem>
      </a>
      <a href="#past-expedition">
        <MenuItem theme={ColorTheme.Orange}>2021 metų vasaros ekspedicija</MenuItem>
      </a>
      <a href="#games">
        <MenuItem theme={ColorTheme.Orange}>ŽAIDIMOLOGIJA</MenuItem>
      </a>
      <a href="#documentary">
        <MenuItem theme={ColorTheme.Orange}>TEMINĖS DOKUMENTIKOS</MenuItem>
      </a>
      <a href="#friends">
        <MenuItem theme={ColorTheme.Orange}>Mūsų draugai</MenuItem>
      </a>
    </ItemList>
  );
}
