import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { PageWrapper, Header, Content } from '../shared/Page';
import { PageImage } from '../shared';
import { ColorTheme } from '../constants/ColorTheme';
import { SectionProps } from './types';
import { device } from '../constants/device';
import { MoreInfo } from '../shared/MoreInfo';

const HeaderWrapper = styled.div`
  text-align: center;
`;

const IFrame = styled.iframe`
  display: block;
  margin: 24px auto 0;
  width: 90%;
  max-width: 560px;

  @media ${device.laptop} {
    margin: 24px auto 0;
    width: 560px;
  }
`;

const components = {
  p: (props) => <Content theme={ColorTheme.White} {...props} />,
};

export function Expeditions({ theme }: SectionProps) {
  return (
    <PageWrapper padding={'0 0 2.5rem 0'} theme={theme}>
      <HeaderWrapper>
        <Header
          textAlign={'center'}
          theme={ColorTheme.White}
          id={'expeditions'}
        >
          KINO EKSPEDICIJOS
        </Header>
      </HeaderWrapper>
      <br />
      <br />
      <PageImage src="expedicijos.jpg" />
      <br />
      <br />
      <MDX components={components}>{`

Mes ne tik keliaujame patys, bet ir kinas keliauja su mumis. Ekspedicinė kinematografija remiasi idėja, kad, kino priemonėmis vaizduojant ekspedicijas, reikia jose ir dalyvauti. Tokio filmavimo tikslas – atlikti jį tiesioginėse ir tikrose kelionės sąlygose. Tai neturi būti scenos pastatytos žiūrovui – pirmiausia jos turi būti patirtos. 

2018 metais Vilniaus kino studijoje buvo pradėti parengiamieji darbai siekiant organizuoti tokias ekspedicijas, sukurtos ir pritaikytos specialios techninės bei organizacinės priemonės. 
`}</MDX>
      <Content theme={ColorTheme.White} textAlign={'center'}>
        ________________________
      </Content>
      <br />
      <IFrame as="img" src="afisa.jpg" alt="" />
      <Content textAlign={'center'} theme={ColorTheme.White}>
        FILMAS „MEILĖ GAMTAI“, 2022
      </Content>
      <MDX
        components={components}
      >{`Nuo 2019 metų įvyko vienuolika gamtakultūrinių kino ekspedicijų, kurių metu buvo filmuojamas meninis-dokumentinis filmas „Meilė gamtai“. Jame – ypatinga kelionė po Dubingių kraštą – senosios Lietuvos ištakų vietą, beieškant žalčių.

Filme tyrinėjamas žalčio simbolis, kuris savyje apjungia ir išreiškia skirtingas gamtakultūrinio pažinimo kryptis: gyvūną, jo gyvenamąją aplinką – mišką ir pelkes, archajinį totemą, mitologinę formą, liaudies meno motyvus, senovines buities praktikas, liaudies mediciną ir t. t.

Čia žalčių ieškojimas ne tik apima platų spektrą įvairaus pobūdžio sąsajų su Lietuvos gamta, geografija, istorija, etnografija, kalba, menu bei mitologija, bet svarbiausia – tai kaip šios sąsajos atsiskleidžia pasakojime ir pačioje kelionėje.

Filmo siužetas nukelia į gilesnę mūsų krašto pažinimo tikrovę, kurioje keičiasi geografinės erdvės suvokimas, išnyksta aiški riba tarp to, kas matoma ir to, kas juntama. Kelionė tampa kartu ir vidiniu keliavimu.

`}</MDX>
      <Content textAlign={'center'}>
        <br />
        <MoreInfo />
      </Content>
      <br />

      <IFrame
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/Fq8C71vPV-8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <IFrame
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/aAS5IpYC0II"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </PageWrapper>
  );
}
