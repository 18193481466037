import React from 'react';
import styled from 'styled-components';
import { device } from '../constants/device';

interface PageWrapperProps {
  padding?: string;
}

export const PageWrapper = styled.section<PageWrapperProps>`
  background-color: ${({ theme }) => theme};
  padding: ${({ padding }) => padding || '0'};
`;

interface HeaderProps {
  margin?: string;
  textAlign?: string;
}

export const Header = styled.h1<HeaderProps>`
  margin: ${({ margin }) => margin || '0 0 0'};
  color: ${({ theme }) => theme};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  font-size: 2rem;
  text-transform: uppercase;
  padding-top: 2.5rem;
  padding: 2.5rem 1.5rem 0;

  @media ${device.laptop} {
    max-width: 1300px;
    margin: 0 auto;
  }
`;

interface ContentProps {
  theme?: string;
  maxWidth?: string;
  textAlign?: string;
}

export const Content = styled.p<ContentProps>`
  padding: 0 1.5rem;
  margin-top: 1.5rem;
  font-size: 1.25rem;
  color: ${({ theme }) => theme};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};

  @media ${device.laptop} {
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'justify')};
    max-width: ${({ maxWidth }) => maxWidth ? maxWidth : '1300px'};
    margin: 1.5rem auto;
  }
`;

export const UnorderedList = styled.ul`
  margin: 0 1.5rem;
  font-size: 20px;
  margin-top: 1rem;
  text-align: left;

  @media ${device.laptop} {
    max-width: 1200px;
    margin: 0 auto;
    text-align: justify;
  }
`;

export const ListItem = styled.li``;
