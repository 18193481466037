import styled from 'styled-components';
import { ColorTheme } from '../constants/ColorTheme';

const FbLogo = styled.img`
  max-width: 16px;
  display: inline-flex;
`;

const Link = styled.a`
  color: ${ColorTheme.Black};
  text-transform:
`;

export function FollowOnFb() {
  return (
    <Link href="https://www.facebook.com/Gamtakult%C5%ABrin%C4%97-draugija-101614475504261" target="_blank">
      <b>Sekite mūsų naujienas: &nbsp;</b>
      <FbLogo src="/facebook.png" alt="facebook" />
    </Link>
  );
}
