import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { device } from '../constants/device';
import { ColorTheme } from '../constants/ColorTheme';
import { PageWrapper, Header, Content } from '../shared/Page';
import { PageImage } from '../shared';
import { SectionProps } from './types';

const ContentWrapper = styled.div`
  @media ${device.laptop} {
    display: flex;
  }
`;

const TextWrapper = styled.div`
  padding: 0 0 2.5rem 0;

  @media ${device.laptop} {
    padding: 0 2rem;
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  height: fit-content;
`;

const components = {
  h1: (props) => <Header theme={ColorTheme.Orange} {...props} />,
  p: (props) => <Content theme={ColorTheme.Orange} {...props} />,
};

export function Nomad({ theme }: SectionProps) {
  return (
    <PageWrapper theme={theme}>
      <ContentWrapper>
        <ImageWrapper>
          <PageImage id={'nomad'} src="/klajokle.jpeg" />
        </ImageWrapper>
        <TextWrapper>
          <MDX components={components}>
            {`# SKRAJOJANTI KLAJOKLĖ

Kartais nėra aišku, kur pasibaigia vienas ir kur prasideda kitas personažas. Mūsų draugijoje Skrajojanti Klajoklė pasirodo įvairiais pavidalais.

Skrajojanti Klajoklė – ekspedicijų vedlė per tankiausius miškus, neišbrendamas pelkes, kalnų keteras, dykumų smėlynus, mitologinius ieškojimus atokiausiuose kampeliuose ir literatūrines keliones po visą pasaulį.

Dar besimokydama Kauno technologijos universiteto gimnazijoje bei vėliau Vytauto Didžiojo universitete buvo žygeivių klubų narė, pėsčiųjų žygiais apkeliavo visus Lietuvos regionus, dalyvavo įvairiuose orientaciniuose bėgimuose, taip pat skautų stovyklose vedė žygius per sunkiai praeinamas vietas.

Gimnazijoje pradėjo ir savo kūrybinį kelią. Vaidino Vido Rašinsko režisuotuose spektakliuose, etiuduose, gilinosi į literatūros kūrinius. Jos poezija, trumpi prozos pamąstymai buvo spausdinami gimnazijos almanachuose. Baigė teatrinę mokyklą-studiją prie Kauno pantomimos teatro.

2009 metais dalyvavo Europos kultūros sostinės projekte „Haiku Vilniui“, apdovanota už moteriškiausius, savaitės, mėnesio haiku, už aktyvumą ir kūrybą. 2018-aisiais, Lietuvos šimtmečio metais, tapo viena iš Maironio lietuvių literatūros muziejaus organizuoto konkurso „100 žodžių Lietuvai“ nugalėtojų, įvertinta už originalumą.

Po dešimtmečio darbo pagrindinėje kino industrijoje, nusprendė pereiti prie naujų jo formų – eksperimentinio kino.

Nuo 2019 metų pradėjo rengti gamtakultūrines ekspedicijas. Tų metų vasarą kartu su Vilniaus kino studijos komanda vedė pirmąsias kino ekspedicijas į Dubingių regioną filmuojant meninį-dokumentinį filmą „Meilė gamtai“. Šio filmo scenarijaus bendraautorė.

2020 metais tęsė ekspedicijas į Dubingius, rengė draugijos organizacinius-metodinius pagrindus.

2021 metais pradėjo parengiamuosius darbus gamtakultūrinėms ekspedicijoms išplėsti, žaidiminių ekspedicinių stovyklų formatui sukurti. Tų metų vasarą vedė gamtakultūrinę ekspediciją po Asvejos ežero apylinkių miškus.

2022 metais debiutavo pagrindiniame Žalčių ieškotojos vaidmenyje filme „Meilė gamtai“.

2023 metais dalyvavo rengiant žaidiminių ekspedicijų dokumentikos ciklą „Žaidimologija“. Šio filmo pagrindinė aktorė ir scenarijaus bendraautorė.

Tais pačiais metais prisijungė prie aktorių kolektyvo Kamilės Gudmonaitės režisuotoje taksofono operoje „Dalykai, kurių neišdrįsau pasakyti, ir dabar jau per vėlu“ Lietuvos nacionaliniame dramos teatre.

Baigiantis metams pradėjo kurti ir atlikti eksperimentinius vaidmenis naujo tipo teatro formate – Beždžionteatryje.

        `}
          </MDX>
        </TextWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
}
