import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import { PageWrapper, Header, Content } from '../shared/Page';
import { ColorTheme } from '../constants/ColorTheme';
import { device } from '../constants/device';
import { SectionProps } from './types';

const HeaderWrapper = styled.div`
  text-align: center;
`;

const IFrame = styled.iframe`
  display: block;
  margin: 24px auto 0;
  width: 90%;
  max-width: 560px;

  @media ${device.laptop} {
    margin: 24px auto 0;
    width: 560px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${device.laptop} {
    flex-wrap: nowrap;
  }
`;

const ImageItem = styled.div`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  flex-grow: 1;
`;

const Image = styled.img`
  height: 60vh;
  object-fit: cover;

  @media ${device.laptop} {
    height: 40vh;
  }
`;

const components = {
  p: (props) => (
    <Content maxWidth={'900px'} theme={ColorTheme.DarkerYellow} {...props} />
  ),
};

export function PassedSummerCamp({ theme }: SectionProps) {
  return (
    <PageWrapper padding={'0 0 2.5rem 0'} theme={theme}>
      <HeaderWrapper>
        <Header
          textAlign={'center'}
          theme={ColorTheme.DarkerYellow}
          id={'past-expedition'}
        >
          2021 METŲ VASAROS EKSPEDICIJA
        </Header>
      </HeaderWrapper>
      <br />
      <br />
      <ImageContainer>
        <ImageItem>
          <Image src="/summerCamp1.jpeg" alt="" />
        </ImageItem>
        <ImageItem>
          <Image src="/summerCamp2.jpeg" alt="" />
        </ImageItem>
        <ImageItem>
          <Image src="/summerCamp3.jpg" alt="" />
        </ImageItem>
        <ImageItem>
          <Image src="/summerCamp4.jpg" alt="" />
        </ImageItem>
        <ImageItem>
          <Image src="/summerCamp5.jpg" alt="" />
        </ImageItem>
      </ImageContainer>


      <MDX components={components}>{`
2021 metų vidurvasario gamtakultūrinė ekspedicija kvietė visus pažinti Lietuvą giliau.

Liepos 17–18 dienomis ekspedicija vyko Dubingių krašte, kurios maršrutas apėmė didelę dalį Asvejos regioninio parko. Keliavome ir stovyklavome miškuose prie ilgiausio Lietuvos ežero – Asvejos.

Ekspedicijos dalyviai žaidė ir susipažino su Dubingių regiono gamtine aplinka bei kultūra, eksperimentiškai patyrė kelionę, vietoves ir save.

Ekspedicijos maršrutas buvo parengtas pagal vietos mitologinių pasakojimų bei liaudies žaidimų siužetus.

Ekspedicijoje pirmą kartą pristatytas filmo „Meilė gamtai“ trumpasis anonsas.

Šios ekspedicijos dalyvių nuotykiai vėliau tapo žaidiminių ekspedicijų dokumentikos „Žaidimologija“ dalimi.

 `}</MDX>
      <IFrame
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/aene0OkvEQI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <IFrame
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/AodHNv7deAY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </PageWrapper>
  );
}
