import React from 'react';
import styled from 'styled-components';
import MDX from '@mdx-js/runtime';
import {
  PageWrapper,
  Header,
  Content,
  UnorderedList,
  ListItem,
} from '../shared/Page';
import { ColorTheme } from '../constants/ColorTheme';
import { SectionProps } from './types';

const HeaderWrapper = styled.div`
  text-align: center;
`;

const Link = styled.a`
  color: currentColor;
  text-decoration: underline;
  text-transform: uppercase;
`;

const components = {
  h1: (props) => (
    <HeaderWrapper>
      <Header
        textAlign={'center'}
        theme={ColorTheme.Yellow}
        id={'summercamp'}
        {...props}
      />
    </HeaderWrapper>
  ),
  h2: (props) => (
    <Content maxWidth={'900px'} textAlign={'center'} theme={ColorTheme.Yellow} {...props} />
  ),
  p: (props) => (
    <Content maxWidth={'900px'} theme={ColorTheme.Yellow} {...props} />
  ),
  ul: (props) => <UnorderedList {...props} />,
  li: (props) => <ListItem {...props} />,
};

export function SummerCamp({ theme }: SectionProps) {
  return (
    <PageWrapper padding={'0 0 2.5rem 0'} theme={theme}>
      <MDX components={components}>{`# 2021 METŲ VASAROS EKSPEDICIJA

## 2021 METŲ VASAROS GAMTAKULTŪRINĖ EKSPEDICIJA Į DUBINGIŲ KRAŠTĄ.
## KVIETIMAS REGISTRUOTIS.

Myli gamtą?

Esi kūrybiškas?

Nebijai iššūkių?

Nori dalyvauti ekspedicijos filmavime?

Šių metų vidurvasario gamtakultūrinė ekspedicija kviečia visus pažinti Lietuvą giliau.

Liepos 17–18 dienomis organizuojama ekspedicija Dubingių krašte, kurios maršrutas apims didelę dalį Asvejos regioninio parko. Keliausime ir stovyklausime prie ilgiausio Lietuvos ežero  – Asvejos.

Ekspedicijos metu susipažinsime su Dubingių regiono gamtine aplinka bei kultūra, eksperimentiškai patirsime kelionę, vietoves ir save.

Ši ekspedicija yra dalis kino ekspedicijų, kurių metu buvo pastatytas meninis-dokumentinis filmas „Meilė gamtai“. Ekspedicijoje bus pirmą kartą pristatytas šio filmo anonsas prieš rudenį vyksiančią jo premjerą.

Visa ekspedicija bus filmuojama televizinio šou formatu. Dalyvių atranka vyksta nuo birželio 15 d. iki liepos 10 d.

Užpildyk dalyvio anketą ir siųsk nurodytu paštu.

Galbūt būtent Tu sudalyvausi viename įsimintiniausių vasaros nuotykių!

Iki susitikimo pakeliui!


 `}</MDX>
      <Content maxWidth={'900px'} theme={ColorTheme.Yellow}>
        <Link href="/files/anketa.doc">
          <b>Dalyvio anketa</b>
        </Link>
        <br />
        <br />
        <Link href="/files/taisykles.pdf">
          <b>Taisyklės</b>
        </Link>
      </Content>
    </PageWrapper>
  );
}
